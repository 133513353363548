import BaseBean from "@/utils/BaseBean";

export interface ITtClaimCardDataObj {
    utilInst:TtClaimCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class TtClaimCardUtil extends BaseBean{
    public dataObj:ITtClaimCardDataObj;

    constructor(proxy:any,dataObj:ITtClaimCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
        this.dataObj.otherParams.bankData=await this.utils.OrderProviderApi.getAllBank({});//查询所有银行
        this.dataObj.otherParams.countryData=await this.utils.OrderProviderApi.getAllCountry({});//查询所有国家
    }
    //选择tt电汇单确认处理
    public async doChoose(id:string):Promise<any>{
        this.dataObj.form.id=id;
        await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
        this.dataObj.form.customerId='';
        this.dataObj.form.amount=0;
    }
}