import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import TtClaimCardUtil,{ITtClaimCardDataObj} from './ttClaimCardUtil';
import TtList from "@/views/project/order/finance/tt/tt/TtList.vue";
export default defineComponent({
    name:'TtClaimCard',
    title:'TT认领',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ITtClaimCardDataObj=reactive<ITtClaimCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                saveConfirm: true,
                saveConfirmContent:"<font color='red'>保存之后就不能修改和删除了，确定保存吗</font>",
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/ttClaim')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                customerId: [utils.UtilPub.commonValidRule(proxy.$t('ttClaim.form.customerId_rule'))],
                amount: [utils.UtilPub.commonValidRule(proxy.$t('ttClaim.form.amount_rule'))]
            },
            otherParams:{
                cardForm:'',//
                unit:'元',//
                customerData:[],//客户
                bankData:[],//银行
                countryData:[],//国家
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtClaimCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'countryId'==params.comboId){
                    return dataObj.otherParams.countryData;
                }
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'bankId'==params.comboId){
                    return dataObj.otherParams.bankData;
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData
                }
            }
        })
        //手续费
        const sxf=computed(()=>{
            if(dataObj.form.hrAmount){
                dataObj.form.sxf=dataObj.form.hrAmount-dataObj.form.amount;
                return dataObj.form.sxf.toFixed(2);
            }else{
                dataObj.form.sxf=0;
                return 0;
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('customerId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.otherParams.unit=res.data.bz==0?'元':'$';
            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
            //当选择了TT汇款单之后，会根据汇款单id重新查询汇款单，这个时候addOrLoad等于/load，但是还未保存，于是customerId为空，
            //我们可以根据这两个条件判断tt电汇单是否被认领保存过了，进而决定是否可以编辑认领单
            if('/load'==addOrLoad && dataObj.form.customerId)dataObj.disabled=true;
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(!dataObj.form.id){
                proxy.$message('请选择TT电汇单');
                return false;
            }
            if(dataObj.form.hrAmount<dataObj.form.amount){
                proxy.$message({type:'warning',message:'实收金额不能大于汇入金额'});
                return false;
            }
            return true;
        }
        //选择TT电汇单
        const chooseHandler=async ()=>{
            await utils.UtilPub.openDialog({proxy:proxy,modelComp:TtList,ownerComp:proxy,
                from:'TtClaimCard',overflow:'hidden',title: '选择TT电汇单',contentHeight:'',showFooterBtn:true,fullscreen:true})
        }
        //选择TT电汇单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            if(selectRows && selectRows.length>0) {
                await dataObj.utilInst.doChoose(selectRows[0].F_ID);
                dataObj.otherParams.unit=selectRows[0].bz=='人民币'?'元':'$';
            }
            return true;
        }
        return{
            ...toRefs(dataObj),proxy,sxf,comboSelect,selectOnChange,beforeOpen,setParam,beforeSaveHandler,
            chooseHandler,sureHandler
        }
    }
});