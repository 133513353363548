export default {
    zh:{
        ttClaim:{
            queryParam:{
                name:'汇款人名称',
                name_placeholder:'请输入汇款人名称',
                customerId:'认领客户',
                customerId_placeholder:'请选择认领客户',
            },
            columns:{
                F_CUSTOMER_ID:'认领客户',
            },
            form:{
                customerId:'认领客户',
                customerId_placeholder:'请选择认领客户',
                customerId_rule: '请选择认领客户',
                amount:'实际收到金额',
                amount_rule: '请选择实际收到金额',
            },
        }
    },
    en:{
        ttClaim:{
            queryParam:{
                name:'汇款人名称',
                name_placeholder:'请输入汇款人名称',
                customerId:'认领客户',
                customerId_placeholder:'请选择认领客户',
            },
            columns:{
                F_CUSTOMER_ID:'认领客户',
            },
            form:{
                customerId:'认领客户',
                customerId_placeholder:'请选择认领客户',
                customerId_rule: '请选择认领客户',
                amount:'实际收到金额',
                amount_rule: '请选择实际收到金额',
            },
        }
    }
}