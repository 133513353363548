import TtClaimCard from './TtClaimCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import TtClaimListUtil,{ITtClaimListDataObj} from './ttClaimListUtil';
import language from './ttClaimLanguage'
export default defineComponent ({
    name: 'TtClaimList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ITtClaimListDataObj=reactive<ITtClaimListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                cardFrom:'TtClaimList',
                queryParam: {},
                modelComp: TtClaimCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/ttClaim/pageData')
            },
            otherParams:{
                customerData:[],//客户
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtClaimListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});